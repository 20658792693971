import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IDataType } from "../types/misc";

// Define a type for the slice state
interface CounterState {
  countries: IDataType[];
  states: IDataType[];
  loading: boolean;
}

// Define the initial state using that type
const initialState: CounterState = {
  countries: [],
  states: [],
  loading: false,
};

export const region = createSlice({
  name: "role",
  initialState,
  reducers: {
    setCountries: (state, action: PayloadAction<IDataType[]>) => {
      state.countries = action.payload;
    },
    setState: (state, action: PayloadAction<IDataType[]>) => {
      state.states = action.payload;
    },
  },
});

export const { setCountries, setState } = region.actions;

export default region.reducer;
