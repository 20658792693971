import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";
import { setQualifications } from "../../store/slices/qualification";

export const get_qualifications = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get("/get-qualifications");

    dispatch(setQualifications(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(setQualifications([]));
    }
  }
};
