
// constants
// import logo from '../../assets/images/icons/white_badge_cropped.png';

const SuspenceLoader = () => {
    return (
        <div className="fixed top-0 right-0 h-screen w-screen z-50 flex justify-center items-center bg-[#243677]">
            <div className="animate-spin rounded-full h-[250px] w-[250px] border-t-2 border-b-2 border-[white] overflow-hidden">

            </div>
            <div className="flex justify-center items-center h-[220px] p-5 w-[220px] absolute overflow-hidden rounded-full">
                {/* <img src={logo} alt="Our Crest" className='object-contain w-full h-full' /> */}
            </div>
        </div>
    )
}

export default SuspenceLoader