import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";
import { setCommunications } from "../../store/slices/communication";

export const get_communications = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get("/get-communication");

    dispatch(setCommunications(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(setCommunications([]));
    }
  }
};
