import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";
import { setLoading } from "../../store/slices/order";
import { ICandidatePoolForm } from "../../store/types/candidate";

export const create_candidate = async (
  dispatch: Function,
  data: ICandidatePoolForm
) => {
  dispatch(setLoading());
  try {
    const response = await axiosInstance.post("/create-candidate", data);

    // dispatch(setGender(response.data));
    console.log(response?.data);
  } catch (error) {
    if (isAxiosError(error)) {
      console.log(error?.response?.data);
    }
  }
  dispatch(setLoading());
};
