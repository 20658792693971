import * as React from "react";

// router
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

// auth and pages
// import { ComingSoon } from '../screens';

// components
import SuspenceLoader from "../components/suspence";
import Hire from "../screens/hire";
import Candidate from "../screens/candidate";

// lazy loading
const Home = React.lazy(() => import("../screens/dashboard"));

// const NotFound = React.lazy(() => import('../screens/notfound'));

const MainNavigation: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/test" element={<SuspenceLoader />} /> */}

        {/* Redirect */}
        <Route path="/jobs" element={<Navigate to="/marketplace" replace />} />
        <Route
          path="/offers"
          element={<Navigate to="/marketplace" replace />}
        />

        <Route
          path="/"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <Home />
            </React.Suspense>
          }
        />
        <Route
          path="/hire"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <Hire />
            </React.Suspense>
          }
        />
        <Route
          path="/candidate"
          element={
            <React.Suspense fallback={<SuspenceLoader />}>
              <Candidate />
            </React.Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default MainNavigation;
