import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";
import { setExperiences } from "../../store/slices/experience";

export const get_experiences = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get("/get-experiences");

    dispatch(setExperiences(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(setExperiences([]));
    }
  }
};
