import React from 'react'

// component
import { Spin } from 'antd';

type SubmitButtonProps = {
    title: string
    onSubmitHandler: () => void | object
    loading?: boolean
    icon?: React.ReactNode
    className?: string
    disabled?: boolean
}

const SubmitButton: React.FC<SubmitButtonProps> = ({ title, onSubmitHandler, loading, icon, className, disabled }) => {
    return (
        <button onClick={onSubmitHandler} disabled={loading || disabled} className={`text-[16px] font-abel py-3 px-6 bg-primary text-[white] rounded-[15px] hover:bg-[#CDD8FE] hover:text-[#243677] hover:border-[#CDD8FE] w-full border-[#0B60D6] shadow-md border font-bold ${className}`}>
            {loading && <Spin />} {title}
        </button>
    )
}

export default SubmitButton