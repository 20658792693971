import React from "react";

// types and state
import {
  ICandidatePoolForm,
  ICandidatePoolFormError,
} from "../../../store/types/candidate";

const useCandidate = () => {
  const [data, dataSet] = React.useState<ICandidatePoolForm>({
    full_name: "",
    phone_number: "",
    email: "",
    d_o_b: "",
    gender_id: "",
    state_of_origin_id: "",
    religion_id: "",
    level_of_education_id: "",
    contact_address: "",
    n_o_k_address: "",
    n_o_k_phone_number: "",
    marital_status_id: "",
    number_of_dependant: "",
    role_id: "",
    years_of_experience: "",
    resume: "",
    experience_and_skills: "",
  });
  const [error, errorSet] = React.useState<ICandidatePoolFormError>({
    full_name: "",
    phone_number: "",
    email: "",
    d_o_b: "",
    gender_id: "",
    state_of_origin_id: "",
    religion_id: "",
    level_of_education_id: "",
    contact_address: "",
    n_o_k_address: "",
    n_o_k_phone_number: "",
    marital_status_id: "",
    number_of_dependant: "",
    role_id: "",
    years_of_experience: "",
    resume: "",
  });

  const onValidate = () => {
    let validate;
    if (!data.full_name) {
      validate = true;
      errorSet((prev) => ({ ...prev, full_name: "warning" }));
    }
    if (!data.email) {
      validate = true;
      errorSet((prev) => ({ ...prev, email: "warning" }));
    }
    if (!data.phone_number) {
      validate = true;
      errorSet((prev) => ({ ...prev, phone_number: "warning" }));
    }
    if (!data.d_o_b) {
      validate = true;
      errorSet((prev) => ({ ...prev, d_o_b: "warning" }));
    }
    if (!data.gender_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, gender_id: "warning" }));
    }
    if (!data.state_of_origin_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, state_of_origin_id: "warning" }));
    }
    if (!data.religion_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, religion_id: "warning" }));
    }
    if (!data.level_of_education_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, level_of_education_id: "warning" }));
    }
    if (!data.contact_address) {
      validate = true;
      errorSet((prev) => ({ ...prev, contact_address: "warning" }));
    }
    if (validate) return false;
    return true;
  };

  const onSubmit = () => {
    let validate;
    if (!data.n_o_k_address) {
      validate = true;
      errorSet((prev) => ({ ...prev, n_o_k_address: "warning" }));
    }
    if (!data.n_o_k_phone_number) {
      validate = true;
      errorSet((prev) => ({ ...prev, n_o_k_phone_number: "warning" }));
    }
    if (!data.marital_status_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, marital_status_id: "warning" }));
    }
    if (!data.role_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, role_id: "warning" }));
    }
    if (!data.years_of_experience) {
      validate = true;
      errorSet((prev) => ({ ...prev, years_of_experience: "warning" }));
    }
    if (!data.resume) {
      validate = true;
      errorSet((prev) => ({ ...prev, resume: "warning" }));
    }
    if (validate) return false;
    return true;
  };

  return { data, error, errorSet, dataSet, onValidate, onSubmit };
};

export default useCandidate;
