import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IDataType } from "../types/misc";

// Define a type for the slice state
interface CounterState {
  experiences: IDataType[];
  loading: boolean;
}

// Define the initial state using that type
const initialState: CounterState = {
  experiences: [],
  loading: false,
};

export const experience = createSlice({
  name: "experience",
  initialState,
  reducers: {
    setExperiences: (state, action: PayloadAction<IDataType[]>) => {
      state.experiences = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { setExperiences, setLoading } = experience.actions;

export default experience.reducer;
