import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IDataType } from "../types/misc";

// Define a type for the slice state
interface CounterState {
  communicationsTime: IDataType[];
  loading: boolean;
}

// Define the initial state using that type
const initialState: CounterState = {
  communicationsTime: [
    { id: "Morning ( 8 am - 11 am)", name: "Morning ( 8 am - 11 am)" },
    { id: "Afternoon (12 noon - 4 pm)", name: "Afternoon (12 noon - 4 pm)" },
    { id: "Evening (4 pm - 7 pm)", name: "Evening (4 pm - 7 pm)" },
  ],
  loading: false,
};

export const communicationTime = createSlice({
  name: "communication-time",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setCommunicationsTime: (state, action: PayloadAction<IDataType[]>) => {
      state.communicationsTime = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { setCommunicationsTime, setLoading } = communicationTime.actions;

export default communicationTime.reducer;
