import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IDataType } from "../types/misc";

// Define a type for the slice state
interface CounterState {
  qualifications: IDataType[];
  loading: boolean;
}

// Define the initial state using that type
const initialState: CounterState = {
  qualifications: [],
  loading: false,
};

export const qualification = createSlice({
  name: "qualification",
  initialState,
  reducers: {
    setQualifications: (state, action: PayloadAction<IDataType[]>) => {
      state.qualifications = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { setQualifications, setLoading } = qualification.actions;

export default qualification.reducer;
