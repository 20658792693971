import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IDataType } from "../types/misc";

// Define a type for the slice state
interface CounterState {
  marital_statuses: IDataType[];
  loading: boolean;
}

// Define the initial state using that type
const initialState: CounterState = {
  marital_statuses: [],
  loading: false,
};

export const marital_status = createSlice({
  name: "role",
  initialState,
  reducers: {
    setMaritalStatus: (state, action: PayloadAction<IDataType[]>) => {
      state.marital_statuses = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { setMaritalStatus, setLoading } = marital_status.actions;

export default marital_status.reducer;
