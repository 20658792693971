import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";
import { setCountries, setState } from "../../store/slices/region";

export const get_states = async (dispatch: Function, id: string) => {
  try {
    const response = await axiosInstance.get(`/get-states/${id}`);

    dispatch(setState(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(setState([]));
    }
  }
};

export const get_countries = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get("/get-countries");

    dispatch(setCountries(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(setCountries([]));
    }
  }
};
