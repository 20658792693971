import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
type IOrder = {
  orders: [];
  loading: boolean;
};

// Define the initial state using that type
const initialState: IOrder = {
  orders: [],
  loading: false,
};

export const order = createSlice({
  name: "order",
  initialState,
  reducers: {
    setOrders: (state, action: PayloadAction<[]>) => {
      state.orders = [];
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { setOrders, setLoading } = order.actions;

export default order.reducer;
