import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// Define a type for the slice state
type Message = { message: string; type: "error" | "success" };
type IMessageState = {
  message: { message: string; type: "error" | "success" } | null;
};

// Define the initial state using that type
const initialState: IMessageState = {
  message: null,
};

export const message = createSlice({
  name: "message",
  initialState,
  reducers: {
    setMessage: (state, action: PayloadAction<Message>) => {
      state.message = action.payload;
    },
    clearMessage: (state) => {
      state.message = null;
    },
  },
});

export const { setMessage, clearMessage } = message.actions;

export default message.reducer;
