import React, { useState } from "react";

// components
import { Steps } from "antd";
import NavbarDark from "../../themes/header-dark";
import Footer from "../../themes/footer";
import db from "../../constants/database";
import Form1 from "./misc/Form1";
import Form2 from "./misc/Form2";

// types and service
import { get_genders } from "../../services/api/gender";
import { useAppDispatch } from "../../store";
import { get_communications } from "../../services/api/communication";
import { get_experiences } from "../../services/api/experience";
import { get_qualifications } from "../../services/api/qualification";
import { get_roles } from "../../services/api/role";
import useOrder from "./hooks/useOrder";

const Hire: React.FC = () => {
  const dispatch = useAppDispatch();
  const [current, setCurrent] = useState(0);
  const { data, error, dataSet, errorSet, onValidateUser, onValidateOrder } =
    useOrder();

  const steps = [
    {
      title: "",
      content: (
        <Form1
          data={data}
          error={error}
          onChange={(name, value) => {
            errorSet((prev) => ({ ...prev, [name]: "" }));
            dataSet((prev) => ({ ...prev, [name]: value }));
          }}
          onNext={() => {
            if (onValidateUser()) next();
          }}
        />
      ),
    },
    {
      title: "",
      content: (
        <Form2
          data={data}
          error={error}
          onChange={(name: string, value: string) => {
            errorSet((prev) => ({ ...prev, [name]: "" }));
            dataSet((prev) => ({ ...prev, [name]: value }));
          }}
          onPrev={() => prev()}
          onSubmit={() => {
            if (onValidateOrder()) return true;
            return false;
          }}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  const next = () => setCurrent(current + 1);
  const prev = () => setCurrent(current - 1);

  const onLoad = React.useCallback(() => {
    get_genders(dispatch);
    get_communications(dispatch);
    get_experiences(dispatch);
    get_qualifications(dispatch);
    get_roles(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    onLoad();
  }, [onLoad]);

  return (
    <div className="font-abel">
      <div className="bg-[black] w-full pb-36">
        <NavbarDark />
        <div className="max-w-[754px] mx-auto text-center md:mt-12 mt-8 px-3">
          <h1 className="text-[#CDD8FE] md:text-5xl text-2xl">
            Ready to Hire Skilled Blue-Collar Staff?
          </h1>
          <p className="text-[white] md:text-2xl text-lg max-w-[576px] mx-auto pt-10">
            Take the hassle out of blue-collar hiring. Get started today and
            experience the difference of working with a trusted partner in
            staffing solutions.
          </p>
          <a href="#form" className="flex justify-center py-7">
            <img src={db.down} alt="down" className="cursor-pointer" />
          </a>
        </div>
        <div className="max-w-[1060px] md:mx-auto rounded-[36px] bg-[white] md;py-[75px] py-10 mx-3">
          <div className="max-w-[835px] mx-auto">
            <h2 className="text-2xl md:text-[33px] text-center pb-4">
              Here is how It Works:
            </h2>
            {db.howItWorks?.map((item) => (
              <div key={item.id} className="flex md:py-8 py-4 px-3">
                <div className="h-[35px] md:h-[65px] w-[35px] md:w-[65px] rounded-2xl bg-[#CDD8FE] flex justify-center items-center md:text-[29px] text-[18px]">
                  {item.number}
                </div>
                <div className="md:max-w-[704px] max-w-[285px] md:ml-7 ml-3">
                  <h3 className="text-[18px] md:text-[22px] pb-2 font-bold">
                    {item.title}
                  </h3>
                  <p className="text-[14px] md:text-[18px]">{item.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div
        id="form"
        className="max-w-[907px] mx-auto md:my-[100px] my-[80px] px-3"
      >
        <h4 className="md:text-[32px] text-[20px] text-[#4E4E4E] text-center pb-[25px] md:pb-[50px]">
          Let us know what you need to get started...
        </h4>
        <div>
          <Steps
            progressDot
            current={current}
            items={items}
            className="pt-4 pr-5"
          />
          <div className="px-5 pt-3">{steps[current].content}</div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Hire;
