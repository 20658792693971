import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";
import { setMaritalStatus } from "../../store/slices/marital_status";

export const get_marital_status = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get("/get-marital-status");

    dispatch(setMaritalStatus(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(setMaritalStatus([]));
    }
  }
};
