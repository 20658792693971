import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IDataType } from "../types/misc";

// Define a type for the slice state
interface CounterState {
  religions: IDataType[];
  loading: boolean;
}

// Define the initial state using that type
const initialState: CounterState = {
  religions: [],
  loading: false,
};

export const religion = createSlice({
  name: "role",
  initialState,
  reducers: {
    setReligion: (state, action: PayloadAction<IDataType[]>) => {
      state.religions = action.payload;
    },
    setLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { setReligion, setLoading } = religion.actions;

export default religion.reducer;
