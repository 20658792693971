import React from "react";

// constants and utilities
import db from "../../constants/database";
import axiosUpload from "../../utility/axiosUpload";
import { Progress } from "antd";

type ISelectFile = {
  name: string;
  onDone: (name: string, value: string) => void;
};

const SelectFile: React.FC<ISelectFile> = ({ name, onDone }) => {
  const [progress, progressSet] = React.useState<number>(0);
  const [loading, loadingSet] = React.useState<boolean>(false);
  const [upload, uploadSet] = React.useState<boolean>(false);

  const onUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    loadingSet(true);
    const { name, files } = e.target;
    if (files?.[0]) {
      try {
        const config = {
          onUploadProgress: (event: any) => {
            const current = Number((event.loaded / event.total) * 100);
            progressSet(Math.floor(current));
          },
        };

        const form_data = new FormData();
        form_data.append("name", files[0]);

        const response = await axiosUpload.post("upload-cv", form_data, config);

        onDone(name, response?.data);
        uploadSet(true);
      } catch (error) {
        uploadSet(false);
      }
    }
    loadingSet(false);
  };

  return (
    <div
      className="w-full"
      onClick={() => document.getElementById(name)?.click()}
    >
      <div className="w-full flex justify-between items-center border border-[#D9D9D9] rounded-[4.5px] px-5 text-[#4E4E4E] text-[16px] font-bold h-[60px] mb-5 cursor-pointer">
        <div>Upload Resume</div>
        <input type="file" name={name} id={name} hidden onChange={onUpload} />
        <div className="flex items-center">
          {loading || upload ? (
            <Progress type="circle" percent={progress} size={40} />
          ) : (
            <img src={db.upload} alt="upload" className="h-[32px]" />
          )}
        </div>
      </div>
    </div>
  );
};

export default SelectFile;
