import React from "react";

// types
import { ErrorWarning } from "../../store/types/misc";

// components

type TextInputProps = {
  container?: string;
  name: string;
  label?: string;
  value: string | number | [];
  required?: boolean;
  placeholder: string;
  onBlur?: () => void;
  prefix?: React.ReactNode;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: "text" | "password" | "date";
  status?: ErrorWarning;
  className?: string;
};

const TextInput: React.FC<TextInputProps> = ({
  container,
  name,
  type,
  label,
  value,
  prefix,
  status,
  onBlur,
  onChange,
  required,
  placeholder,
}) => {
  const borderColor = status ? "border-[red]" : "border-[#D9D9D9]";
  return (
    <div className={`my-4 font-abel ${container}`}>
      {label && (
        <label className="font-[400] text-[16px] block text-[#4E4E4E] capitalize`">
          {label} {required && <span className="text-[red]">*</span>}
        </label>
      )}
      <div
        className={`flex items-center text-xl py-3 px-6 rounded-[4.5px] border hover:border-[#243677] bg-[#ffffff] focus-within:border-[#243677] h-[60px] ${borderColor}`}
      >
        {prefix && <div>{prefix}</div>}
        <div className="w-full bg-[#fff]">
          <input
            type={type}
            name={name}
            value={value}
            onBlur={onBlur}
            placeholder={placeholder}
            onChange={onChange}
            className="text-[16px] border-none placeholder:font-abel outline-none w-full"
          />
        </div>
      </div>
    </div>
  );
};

export default TextInput;
