import { configureStore } from "@reduxjs/toolkit";

// ...
import gender from "./slices/gender";
import role from "./slices/role";
import qualification from "./slices/qualification";
import experience from "./slices/experience";
import communication from "./slices/communication";
import message from "./slices/message";
import order from "./slices/order";
import marital_status from "./slices/marital_status";
import religion from "./slices/religion";
import region from "./slices/region";
import communicationTime from "./slices/comm_time";

export const store = configureStore({
  reducer: {
    gender,
    communication,
    communicationTime,
    experience,
    role,
    qualification,
    message,
    order,
    marital_status,
    religion,
    region,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
