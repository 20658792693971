import React from "react";
import { IRequestOrder, IRequestOrderError } from "../../../store/types/order";

const useOrder = () => {
  const [data, dataSet] = React.useState<IRequestOrder>({
    full_name: "",
    contact_address: "",
    phone_number: "",
    email: "",
    communication_id: "",
    communication_time: "",
    role_id: "",
    qualification_id: "",
    level_of_experience_id: "",
    gender_id: "",
    age_range: "",
    salary_range: "",
    message: "",
    organization_name: "",
  });

  const [error, errorSet] = React.useState<IRequestOrderError>({
    full_name: "",
    contact_address: "",
    phone_number: "",
    email: "",
    communication_id: "",
    communication_time: "",
    role_id: "",
    qualification_id: "",
    level_of_experience_id: "",
    gender_id: "",
    age_range: "",
    salary_range: "",
    message: "",
    organization_name: "",
  });

  const onValidateUser = () => {
    let validate;
    if (!data.full_name) {
      validate = true;
      errorSet((prev) => ({ ...prev, full_name: "warning" }));
    }
    if (!data.contact_address) {
      validate = true;
      errorSet((prev) => ({ ...prev, contact_address: "warning" }));
    }
    if (!data.phone_number) {
      validate = true;
      errorSet((prev) => ({ ...prev, phone_number: "warning" }));
    }
    if (!data.email) {
      validate = true;
      errorSet((prev) => ({ ...prev, email: "warning" }));
    }
    if (!data.communication_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, communication_id: "warning" }));
    }
    if (!data.communication_time) {
      validate = true;
      errorSet((prev) => ({ ...prev, communication_time: "warning" }));
    }
    if (validate) return false;
    return true;
  };

  const onValidateOrder = () => {
    let validate;
    if (!data.role_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, role_id: "warning" }));
    }
    if (!data.qualification_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, qualification_id: "warning" }));
    }
    if (!data.level_of_experience_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, level_of_experience_id: "warning" }));
    }
    if (!data.gender_id) {
      validate = true;
      errorSet((prev) => ({ ...prev, gender_id: "warning" }));
    }
    if (!data.age_range) {
      validate = true;
      errorSet((prev) => ({ ...prev, age_range: "warning" }));
    }
    if (!data.salary_range) {
      validate = true;
      errorSet((prev) => ({ ...prev, salary_range: "warning" }));
    }
    if (validate) return false;
    return true;
  };

  return { data, error, dataSet, errorSet, onValidateUser, onValidateOrder };
};

export default useOrder;
