import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";
import { setReligion } from "../../store/slices/religion";

export const get_religions = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get("/get-religions");

    dispatch(setReligion(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
      dispatch(setReligion([]));
    }
  }
};
