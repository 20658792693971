import React from "react";
import SelectInput from "../../../components/forms/SelectInput";
import TextInput from "../../../components/forms/TextInput";
import { useAppDispatch, useAppSelector } from "../../../store";
import {
  ICandidatePoolForm,
  ICandidatePoolFormError,
} from "../../../store/types/candidate";
import { get_states } from "../../../services/api/region";
import SubmitButton from "../../../components/forms/SubmitButton";

type ICandidateForm = {
  data: ICandidatePoolForm;
  error: ICandidatePoolFormError;
  onChange: (name: string, value: string) => void;
  onNext: () => void;
};

const CandidateForm: React.FC<ICandidateForm> = ({
  data,
  error,
  onChange,
  onNext,
}) => {
  const dispatch = useAppDispatch();
  const [country, countrySet] = React.useState<string>("");
  const states = useAppSelector(({ region }) => region.states);
  const genders = useAppSelector(({ gender }) => gender.genders);
  const religions = useAppSelector(({ religion }) => religion.religions);
  const countries = useAppSelector(({ region }) => region.countries);
  const qualifications = useAppSelector(
    ({ qualification }) => qualification.qualifications
  );
  return (
    <>
      <div className="w-full">
        <TextInput
          value={data.full_name}
          placeholder="Full Name"
          name="full_name"
          status={error.full_name}
          onChange={(e) => {
            const { name, value } = e.target;
            onChange(name, value);
            onChange("organization_name", value);
          }}
        />
      </div>

      <div className="w-full">
        <TextInput
          value={data.email}
          placeholder="Email Address"
          name="email"
          status={error.email}
          onChange={(e) => {
            const { name, value } = e.target;
            onChange(name, value);
          }}
        />
      </div>

      <div className="w-full">
        <TextInput
          value={data.phone_number}
          status={error.phone_number}
          placeholder="Phone Number"
          name="phone_number"
          onChange={(e) => {
            const { name, value } = e.target;
            onChange(name, value);
          }}
        />
      </div>

      <div className="w-full">
        <TextInput
          status={error.d_o_b}
          value={data.d_o_b}
          placeholder="Date of Birth"
          name="d_o_b"
          type="date"
          onChange={(e) => {
            const { name, value } = e.target;
            onChange(name, value);
          }}
        />
      </div>

      <div className="w-full">
        <SelectInput
          label="Gender"
          name="gender_id"
          status={error.gender_id}
          items={genders}
          onResponse={(name, value) => onChange(name, value)}
        />
      </div>

      <div className="w-full">
        <SelectInput
          label="Country of Origin"
          name="country"
          items={countries}
          value={country}
          onResponse={(_name, value) => {
            countrySet(value);
            get_states(dispatch, value);
          }}
        />
      </div>

      <div className="w-full">
        <SelectInput
          label="State of Origin"
          name="state_of_origin_id"
          status={error.state_of_origin_id}
          items={states}
          onResponse={(name, value) => onChange(name, value)}
        />
      </div>

      <div className="w-full">
        <SelectInput
          label="Religion"
          name="religion_id"
          status={error.religion_id}
          items={religions}
          onResponse={(name, value) => onChange(name, value)}
        />
      </div>

      <div className="w-full">
        <SelectInput
          label="Level of Education"
          name="level_of_education_id"
          status={error.level_of_education_id}
          items={qualifications}
          onResponse={(name, value) => onChange(name, value)}
        />
      </div>

      <div className="w-full">
        <TextInput
          value={data.contact_address}
          placeholder="Contact Address"
          name="contact_address"
          status={error.contact_address}
          onChange={(e) => {
            const { name, value } = e.target;
            onChange(name, value);
          }}
        />
      </div>

      <div className="flex justify-center mt-8 w-100">
        <div className="w-[150px]">
          <SubmitButton title="Next" onSubmitHandler={() => onNext()} />
        </div>
      </div>
    </>
  );
};

export default CandidateForm;
