import React from "react"
import db from "../../constants/database"
import { Link } from "react-router-dom"

const NavbarDark: React.FC = () => {

    return (
        <div className='w-full font-abel'>
            <div className='flex justify-between items-center container mx-auto py-6 px-3'>
                <Link to='/'>
                    <img src={db.logoWhite} alt='25th blue logo' className='md:w-[76.75px] w-[50px]' />
                </Link>
                <Link to='/hire' className='border-[#0B60D6] border-[2px] bg-primary py-3 w-[164px] md:w-[174px] h-[52px] flex justify-center items-center rounded-[15px] text-[13px] md:text-[16px] b_shadow text-[#fff]'>Hire a Domestic Staff</Link>
            </div>
        </div>
    )
}

export default NavbarDark