import SelectInput from "../../../components/forms/SelectInput";
import SubmitButton from "../../../components/forms/SubmitButton";
import TextInput from "../../../components/forms/TextInput";
import TextInputBox from "../../../components/forms/TextInputBox";
import { create_order } from "../../../services/api/order";
import { useAppDispatch, useAppSelector } from "../../../store";
import { IRequestOrder, IRequestOrderError } from "../../../store/types/order";

type IOrganizationData = {
  onPrev: () => void;
  onChange: (name: string, value: string) => void;
  onSubmit: () => boolean;
  data: IRequestOrder;
  error: IRequestOrderError;
};

const Form2: React.FC<IOrganizationData> = ({
  data,
  error,
  onPrev,
  onSubmit,
  onChange,
}) => {
  const dispatch = useAppDispatch();
  const genders = useAppSelector(({ gender }) => gender.genders);
  const roles = useAppSelector(({ role }) => role.roles);
  const loading = useAppSelector(({ order }) => order.loading);

  const experiences = useAppSelector(
    ({ experience }) => experience.experiences
  );
  const qualifications = useAppSelector(
    ({ qualification }) => qualification.qualifications
  );

  return (
    <>
      <div className="w-full">
        <SelectInput
          label="What role are you hiring for?"
          name="role_id"
          status={error.role_id}
          items={roles}
          onResponse={(name, value) => onChange(name, value)}
        />
      </div>
      <div className="w-full">
        <SelectInput
          label="Qualifications required for this role"
          name="qualification_id"
          status={error.qualification_id}
          items={qualifications}
          onResponse={(name, value) => onChange(name, value)}
        />
      </div>
      <div className="w-full">
        <SelectInput
          label="Preferred level of Experience"
          name="level_of_experience_id"
          items={experiences}
          status={error.level_of_experience_id}
          onResponse={(name, value) => onChange(name, value)}
        />
      </div>
      <div className="w-full">
        <SelectInput
          label="Preferred Gender"
          name="gender_id"
          items={genders}
          status={error.gender_id}
          onResponse={(name, value) => onChange(name, value)}
        />
      </div>

      <div className="w-full">
        <TextInput
          name="age_range"
          value={data.age_range}
          status={error.age_range}
          placeholder="Preferred Age Range"
          onChange={(e) => {
            const { name, value } = e.target;
            onChange(name, value);
          }}
        />
      </div>

      <div className="w-full">
        <TextInput
          name="salary_range"
          value={data.salary_range}
          status={error.salary_range}
          placeholder="Preferred Salary Range"
          onChange={(e) => {
            const { name, value } = e.target;
            onChange(name, value);
          }}
        />
      </div>

      <div className="w-full">
        <TextInputBox
          label=""
          name="message"
          placeholder="Any other relevant information you want us to know to help fill the position?"
          value={data.message}
          onChange={(e) => {
            const { name, value } = e.target;
            onChange(name, value);
          }}
        />
      </div>

      <div className="flex justify-center mt-8 w-[100]">
        <div className="w-[150px]">
          <SubmitButton title="Back" onSubmitHandler={() => onPrev()} />
        </div>

        <div className="w-[150px] ml-3">
          <SubmitButton
            title="Submit"
            loading={loading}
            disabled={loading ? true : false}
            onSubmitHandler={() => {
              const validate = onSubmit();
              if (validate) create_order(dispatch, data);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Form2;
