// import React from "react";

// environment
// import env from "./config/env";

// navigation
import Router from "./router";

import { Provider } from "react-redux";
import { store } from "./store/store";

// store
// import { Provider } from "react-redux";

// disable
// import { disableReactDevTools } from "@fvilers/disable-react-devtools";

// if (env.enviroment === "production") {
//   disableReactDevTools();
// }

function App() {
  return (
    <Provider store={store}>
      <div>
        <Router />
      </div>
    </Provider>
  );
}

export default App;
