import { isAxiosError } from "axios";
import axiosInstance from "../../utility/axiosInstance";
import { setRoles } from "../../store/slices/role";

export const get_roles = async (dispatch: Function) => {
  try {
    const response = await axiosInstance.get("/get-roles");

    dispatch(setRoles(response.data));
  } catch (error) {
    if (isAxiosError(error)) {
      console.log(error?.response?.data);
    }
  }
};
