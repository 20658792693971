import React from "react";
import SelectInput from "../../../components/forms/SelectInput";
import TextInput from "../../../components/forms/TextInput";
import { useAppSelector } from "../../../store";
import SubmitButton from "../../../components/forms/SubmitButton";
import { IRequestOrder, IRequestOrderError } from "../../../store/types/order";

type IOrganizationData = {
  onNext: () => void;
  onChange: (name: string, value: string) => void;
  data: IRequestOrder;
  error: IRequestOrderError;
};

const Form1: React.FC<IOrganizationData> = ({
  data,
  error,
  onChange,
  onNext,
}) => {
  const preferred_communication = useAppSelector(
    ({ communication }) => communication.communications
  );
  const preferred_communication_time = useAppSelector(
    ({ communicationTime }) => communicationTime.communicationsTime
  );

  return (
    <>
      <div className="w-full">
        <TextInput
          placeholder="Full Name"
          name="full_name"
          value={data.full_name}
          status={error?.full_name}
          onChange={(e) => {
            const { name, value } = e.target;
            onChange(name, value);
            onChange("organization_name", value);
          }}
        />
      </div>
      <div className="w-full">
        <TextInput
          placeholder="Contact Address"
          value={data.contact_address}
          name="contact_address"
          status={error?.contact_address}
          onChange={(e) => {
            const { name, value } = e.target;
            onChange(name, value);
          }}
        />
      </div>
      <div className="w-full">
        <TextInput
          placeholder="Phone Number"
          value={data.phone_number}
          status={error.phone_number}
          name="phone_number"
          onChange={(e) => {
            const { name, value } = e.target;
            onChange(name, value);
          }}
        />
      </div>
      <div className="w-full">
        <TextInput
          name="email"
          value={data.email}
          status={error.email}
          placeholder="Email Address"
          onChange={(e) => {
            const { name, value } = e.target;
            onChange(name, value);
          }}
        />
      </div>
      <div className="w-full">
        <SelectInput
          label="Preferred Mode of Communication"
          name="communication_id"
          //   required={true}
          status={error.communication_id}
          items={preferred_communication}
          onResponse={(name, value) => {
            onChange(name, value);
          }}
        />
      </div>
      <div className="w-full">
        <SelectInput
          label="Preferred Communication Time"
          name="communication_time"
          status={error.communication_time}
          items={preferred_communication_time}
          onResponse={(name, value) => {
            onChange(name, value);
          }}
        />
      </div>

      <div className="flex justify-center mt-8 w-[100]">
        <div className="w-[150px] ml-3">
          <SubmitButton title="Next" onSubmitHandler={onNext} />
        </div>
      </div>
    </>
  );
};

export default Form1;
